let awsRegion = 'us-west-2';

export default {
 api_urls: {
   reporting: 'https://9jhvspiy9h.execute-api.us-west-2.amazonaws.com/api',
   geojson: 'https://93qo6gphg8.execute-api.us-west-2.amazonaws.com/api',
   overlays: 'https://1xs7he3dk1.execute-api.us-west-2.amazonaws.com/api',
 },
 google_api_url: 'AIzaSyDI0B09O3FFEn1-usWMdtJAzat9O7SbvR4',
 main_company_term_id: 6,
 crm_base_url: 'https://crm.testing.saas.forwoodsafety.com',
 forwood_id_url: 'https://id.testing.saas.forwoodsafety.com?redirect_uri=https://geoeditor.testing.saas.forwoodsafety.com',
 custom_tile_url: 'https://mapoverlays.geoeditor.testing.saas.forwoodsafety.com',
 Auth: {
   userPoolId: 'us-west-2_IN4qXIpGt',
   userPoolWebClientId: '12c0kjjh0crqttep8vv6kjsvi0',
   cookieStorage: {
     domain: '.testing.saas.forwoodsafety.com',
     secure: true,
   },
 },
 intercom_enabled: 'True',
 env: 'testing'
};
